import {
  ArrayUtils,
  DaafMessage,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import { Enums } from "app/Enums";
import produce from "immer";
import TrdReduxHelper from "page/trd/editor/render/TrdReduxHelper";
import TrdService from "services/TrdService";

class TrdUtil {
  static DBType = {
    MySql: "MYSQL",
    MSSQL: "MSSQL",
    ORACLE: "ORACLE",
  };

  static QuerySet = {
    queryComment: "", //쿼리 주석
    queryContents: "", //쿼리 내용
    queryKey: "", // 쿼리 실행 키
    querySuccessYn: "", // 쿼리 성공 여부
    baseTrdMstHistoryId: 0, // 쿼리 생성시 대조한 기준 데이터의 MstId
    fieldNm: "", //쿼리 실행 컬럼(필드)
    tableNm: "", //쿼리 실행 테이블
    tableNm2: "", //테이블 명 2 -> 관계 쿼리 저장시 타겟 테이블 명 저장
  };

  //쿼리 생성시 호출되는 TRD MST HISTORYs
  static baseTrdMstHistoryId = 0;

  static compareIgnoreField = [...Enums.TrdWhoColumns, "trdMst", "position"];

  /**
   * 설정된 컬럼 무시한 오브젝트를 생성함
   * @param {*} obj
   * @param {*} ignoreFields
   * @returns
   */
  static generateObjWithIgnoreObj = (obj, ignoreFields = []) => {
    const newObj = produce(obj, (draft) => {
      for (const objKey in obj) {
        if (
          StringUtils.includes(objKey, [
            ...this.compareIgnoreField,
            ...ignoreFields,
          ])
        ) {
          delete draft[objKey];
        } else if (
          ArrayUtils.isArray(obj[objKey]) ||
          ObjectUtils.isObject(obj[objKey])
        ) {
          draft[objKey] = this.generateObjWithIgnoreObj(
            obj[objKey],
            ignoreFields
          );
        }
      }
    });
    return newObj;
  };

  /**
   * TRD에서 수정된 데이터가 어떤부분인지를 리턴함
   * @param {*} currentTrd
   * @param {*} prevTrd
   * @returns
   */
  static getChangedData = (currentTrd, prevTrd) => {
    let changedTrd = null;
    /**
     * 1. TRD 데이터 비교
     * 2. Area 데이터 비교
     * 3. Table 데이터 비교
     *  3-1. 테이블 정보 비교
     *  3-2. 필드 비교
     *  3-3. 릴레이션 비교
     *
     * 현재(currentTrd) 기준 과거와 비교한다.
     * 업데이트 내용은 Object에 changeType을 추가한다.
     * changeType
     *  1. add : 신규 추가
     *  2. update : 수정
     *  3. delete : 삭제
     * 수정을 제외한 필드 또는 테이블 길이가 다를 경우
     * prevTrd를 조회하여 비교한다.
     */

    //MST 정보 비교
    let mstInfo = [];
    for (const key of Object.keys(currentTrd)) {
      if (
        StringUtils.includesIgnoreCase(key, [
          ...Enums.TrdWhoColumns,
          "tableList",
          "areaList",
          "userId",
          "trdMemo",
          "trdId",
          "appEnvId",
          "trdHistoryId",
          "queryList",
        ])
      ) {
        continue;
      }
      if (currentTrd[key] !== prevTrd[key] || key === "updtUserId") {
        mstInfo.push({
          key,
          prevData: prevTrd[key],
          currentData: currentTrd[key],
        });
      }
    }
    changedTrd = mstInfo;

    //Table 세팅
    /**
     * 1. 삭제된 테이블 확인
     * 2. 수정된 테이블 확인
     * 3. 추가된 테이블 확인
     */
    let deletedTable = [];
    let changedTableList = [];
    let addedTable = [];

    // 테이블 리스트 비교
    const prevTables = prevTrd.tableList.reduce((acc, table) => {
      acc[table.tableMstId] = table;
      return acc;
    }, {});

    const currentTables = currentTrd.tableList.reduce((acc, table) => {
      acc[table.tableMstId] = table;
      return acc;
    }, {});

    // 추가된 테이블 찾기
    for (const id in currentTables) {
      if (!prevTables[id]) {
        addedTable.push(currentTables[id]);
      }
      //삭제된 테이블도 처리
      if (StringUtils.equalsIgnoreCase(currentTables[id].useYn, "N")) {
        deletedTable.push(currentTables[id]);
      }
    }

    // 과거 기록에서 삭제된 테이블 찾기
    for (const id in prevTables) {
      if (!currentTables[id]) {
        deletedTable.push(prevTables[id]);
      }
    }

    for (const tableMstId in currentTables) {
      if (prevTables[tableMstId]) {
        const prevTable = prevTables[tableMstId];
        const currentTable = currentTables[tableMstId];
        const updatedTableData = {};

        for (const tableInfoKey of Object.keys(currentTable)) {
          if (
            StringUtils.includesIgnoreCase(tableInfoKey, [
              ...Enums.TrdWhoColumns,
              "trdMst",
              "position",
            ])
          ) {
            continue;
          } else if (
            StringUtils.equalsIgnoreCase(tableInfoKey, "trdTableField")
          ) {
            //필드 내용이 바뀐경우
            //삭제된 필드
            let deletedField = [];
            //수정된 필드
            let updatedField = [];
            //추가된 필드
            let addedField = [];
            if (!prevTable[tableInfoKey]) return;
            // trdTableField 비교
            // trdId와 trdMstId는 수정될일 없음 -> 수정보다 삭제되는 일만 있기 때문에 구태여 비교로직에 넣지않음
            // 현재 데이터는 Object 형태로 가지고 있고 , 이전 데이터는 id(Long) 으로 가져오기 때문에 무조건적으로 비교가 되어 해당 keyValueSet을 제거함
            const prevFields = prevTable.trdTableField.reduce((acc, field) => {
              acc[this.getFieldNm(field)] = this.generateObjWithIgnoreObj(
                field,
                ["trdId", "trdMstId", "tableMstId"]
              );
              return acc;
            }, {});

            const currentFields = currentTable.trdTableField.reduce(
              (acc, field) => {
                acc[this.getFieldNm(field)] = this.generateObjWithIgnoreObj(
                  field,
                  ["trdId", "trdMstId", "tableMstId"]
                );
                return acc;
              },
              {}
            );

            // 추가된 필드 찾기
            for (const fieldId in currentFields) {
              if (!prevFields[fieldId]) {
                addedField.push(currentFields[fieldId]);
              }
            }

            // 삭제된 필드 찾기
            for (const fieldId in prevFields) {
              if (!currentFields[fieldId]) {
                deletedField.push(prevFields[fieldId]);
              }
            }
            //수정된 필드 찾기
            for (const fieldId in currentFields) {
              if (prevFields[fieldId] && currentFields[fieldId]) {
                const currentField = currentFields[fieldId];
                const prevField = prevFields[fieldId];
                const changed = {};
                for (const fieldKey of Object.keys(currentField)) {
                  if (
                    StringUtils.includes(fieldKey, [
                      ...Enums.TrdWhoColumns,
                      "fieldHistoryId",
                      "type",
                      "useYn",
                    ])
                  )
                    continue;
                  if (
                    JSON.stringify(prevField[fieldKey]) !==
                    JSON.stringify(currentField[fieldKey])
                  ) {
                    changed[fieldKey] = {
                      prevData: prevField[fieldKey],
                      currentData: currentField[fieldKey],
                    };
                    // updatedField.push({
                    //   //이전 필드를 기준으로 한다. -> 쿼리 생성시 이전 필드명으로 찾아야하기 때문
                    //   fieldNm: prevField.element.elementCd,
                    //   fieldInfo: currentField,
                    //   key: fieldKey,
                    //   prevData: prevField[fieldKey],
                    //   currentData: currentField[fieldKey],
                    // });
                  }
                }
                if (!ObjectUtils.isEmpty(changed)) {
                  updatedField.push({
                    fieldNm: this.getFieldNm(prevField),
                    fieldInfo: currentField,
                    changed,
                  });
                }
              }
            }
            if (
              !ArrayUtils.isEmpty(addedField) ||
              !ArrayUtils.isEmpty(deletedField) ||
              !ArrayUtils.isEmpty(updatedField)
            ) {
              updatedTableData[tableInfoKey] = {
                prevData: prevTable[tableInfoKey],
                currentData: currentTable[tableInfoKey],
                addedField,
                deletedField,
                updatedField,
              };
            }
          } else if (StringUtils.equalsIgnoreCase(tableInfoKey, "relation")) {
            const compareIgonoreField = [
              "targetTableMstId",
              "tableMstId",
              "tableMstHistoryId",
              "relationHistoryId",
              "trdArea",
              "trdMst",
              "relationDtlHistoryId",
              "fieldHistoryId",
              "trdId",
              "updtUserId",
              "relationId",
            ];
            //관계가 바뀐 경우
            const prevRelation = this.generateObjWithIgnoreObj(
              prevTable[tableInfoKey],
              compareIgonoreField
            );
            const currentRelation = this.generateObjWithIgnoreObj(
              currentTable[tableInfoKey],
              compareIgonoreField
            );
            const addedRelation = [];
            const deletedRelation = [];
            const updatedRelation = [];
            currentRelation.forEach((cr) => {
              //기존에 있는 경우
              const up = prevRelation.find(
                (pr) => pr.relationNm === cr.relationNm
              );
              if (up) {
                const prevJoinColumn = this.generateObjWithIgnoreObj(
                  up.joinColumns,
                  compareIgonoreField
                );
                const currentJoinColumn = this.generateObjWithIgnoreObj(
                  cr.joinColumns,
                  compareIgonoreField
                );
                if (
                  JSON.stringify(prevJoinColumn) !==
                  JSON.stringify(currentJoinColumn)
                ) {
                  updatedRelation.push(cr);
                }
              }
              if (!up) {
                addedRelation.push(cr);
              }
            });
            //삭제된 관계 검색
            prevRelation.forEach((pr) => {
              const maintainedRelation = currentRelation.find(
                (cr) => pr.relationNm === cr.relationNm
              );
              if (!maintainedRelation || maintainedRelation.useYn === "N") {
                deletedRelation.push(pr);
              }
            });
            updatedTableData[tableInfoKey] = {
              prevData: prevTable[tableInfoKey],
              currentData: currentTable[tableInfoKey],
              addedRelation,
              updatedRelation,
              deletedRelation,
            };
          } else if (StringUtils.equalsIgnoreCase(tableInfoKey, "trdArea")) {
            //영역이 바뀐
          } else if (prevTable[tableInfoKey] !== currentTable[tableInfoKey]) {
            if (
              StringUtils.includes(tableInfoKey, [
                ...Enums.TrdWhoColumns,
                "tableMstHistoryId",
                "type",
                "useYn",
              ])
            )
              continue;
            //테이블 정보가 바뀐 경우
            updatedTableData[tableInfoKey] = {
              prevData: prevTable[tableInfoKey],
              currentData: currentTable[tableInfoKey],
            };
          }
        }
        if (!ObjectUtils.isEmpty(updatedTableData)) {
          updatedTableData.tableNm = currentTable.tablePhysicalNm;
          updatedTableData.fieldList = currentTable.trdTableField;
          changedTableList.push(updatedTableData);
        }
      }
    }
    return { changedTrd, changedTableList, deletedTable, addedTable };
  };

  /**
   * 개행문자 추가하는 로직
   * @param {*} number
   * @returns
   */
  static addLine = (number) => {
    return `\n`.repeat(number ? number : 1);
  };

  /**
   * 쿼리 생성시 탭 추가
   * @param {*} number
   * @returns
   */
  static addTab = (number) => {
    return `\t`.repeat(number ? number : 1);
  };

  /**
   * 쿼리생성시 사용
   *  데이터 길이 가져오는 메서드
   * @param {*} field
   */
  static getDataLength = (field) => {
    if (field.element.domain.dataLength) {
      return `(${field.element.domain.dataLength})`;
    } else {
      return "";
    }
  };

  /**
   * 쿼리생성시 사용
   *  데이터 타입 가져오는 메서드
   * @param {*} field
   */
  static getDataType = (field, dbType = this.DBType.MySql) => {
    if (!field.element.domain)
      throw new Error(`Domain of ${field.physicalNm} column is undefined`);
    const dataType = StringUtils.toUpperCase(field.element.domain.dataType);
    const unicodeYn = StringUtils.toUpperCase(field.element.domain.unicodeYn);
    if (
      StringUtils.equalsIgnoreCase(dataType, "varchar") &&
      StringUtils.equalsIgnoreCase(unicodeYn, "Y")
    ) {
      if (StringUtils.equalsIgnoreCase(dbType, this.DBType.MSSQL)) {
        return "NVARCHAR";
      } else if (StringUtils.equalsIgnoreCase(dbType, this.DBType.ORACLE)) {
        return "VARCHAR2";
      } else {
        return dataType;
      }
    } else {
      return dataType;
    }
  };

  /**
   * 쿼리 생성시 사용
   * 기본값 가져오는 메서드
   * @param {*} field
   * @returns
   */
  static getDefaultValue = (field, dbType) => {
    if (field.fieldDefaultValue) {
      switch (Enums.getDataCase(this.getDataType(field, dbType))) {
        case Enums.CaseType.String:
          return `'${field.fieldDefaultValue}'`;
        case Enums.CaseType.Date:
        case Enums.CaseType.Decimal:
        case Enums.CaseType.Number:
          return field.fieldDefaultValue;
        default:
          break;
      }
    } else {
      return "";
    }
  };
  /**
   * 쿼리 생성시 사용
   * 코멘트 뽑아내는 메서드
   * @param {*} field
   * @returns
   */
  static getFieldComment = (field) => {
    if (field.element.elementNm) {
      return field.element.elementNm;
    } else {
      return "";
    }
  };

  /**
   * 필드명(엘리먼트 코드 가져옴)
   * @param {*} field
   * @returns
   */
  static getFieldNm = (field) => {
    if (!field.element) {
      return field.physicalNm;
    } else if (field.element.elementCd) {
      return field.element.elementCd;
    } else {
      return "";
    }
  };

  /**
   * DB명을 넣을 수 있는 공간을 만듬
   * 실제 DB 명은 런타임에서 replace 함
   * @returns
   */
  static getDatabaseNm = () => {
    return "#{databaseName}";
  };

  /**
   * 쿼리를 생성함
   * @param {*} trdMst
   * @param {*} dbType
   * @returns
   */
  static generateQuery = async (trdMst, dbType = this.DBType.MySql) => {
    /**
     * Real Table에 반영될 목록
     * create, dropColumn, dropTable, addColumn -> 각 항목별 PK ID 만 받아와서 적용한다.
     * alterColum -> 각 칼럼 전체 정보를 가져와서 적용한다.
     */
    try {
      const prevTrd = await TrdService.getPrevAppliedTrdMst({
        trdId: trdMst.trdId,
      });
      let queryArr = [];
      if (prevTrd?.data) {
        this.baseTrdMstHistoryId = prevTrd.data.trdHistoryId;
        //이전에 쿼리를 실행한 기록이 있는 경우
        const changedData = this.getChangedData(trdMst, prevTrd.data);
        const { addedTable = [], deletedTable, changedTableList } = changedData;
        //테이블 추가
        if (!ArrayUtils.isEmpty(addedTable)) {
          let relationList = [];
          for (const table of addedTable) {
            const createQuerySet = this.generateTableCreateQuery(table, dbType);
            if (createQuerySet) {
              createQuerySet.createTable = table.tableMstId;
              //import Table은 create 하지 않음
              if (!table.importTableId) {
                queryArr.push(createQuerySet);
              }
              if (!ArrayUtils.isEmpty(table.relation))
                relationList.push(table.relation);
            }
          }

          if (!ArrayUtils.isEmpty(relationList)) {
            for (const relations of relationList) {
              for (const relation of relations) {
                if (relation.useYn !== "N") {
                  queryArr.push({
                    ...this.generateForeignKeyQuery(relation, trdMst, dbType),
                    alterRelationId: relation.relationId,
                  });
                }
              }
            }
          }
        }
        //테이블 삭제
        if (!ArrayUtils.isEmpty(deletedTable)) {
          for (const table of deletedTable) {
            queryArr.push({
              ...this.generateTableDropQuery(table, trdMst, dbType),
            });
          }
        }

        //테이블 변경
        //변경된 테이블 중 삭제된 테이블에 있으면 제외하고 실행
        const filteredChangedTableList = changedTableList.filter(
          (ct) => !deletedTable.find((dt) => dt.tablePhysicalNm === ct.tableNm)
        );
        if (!ArrayUtils.isEmpty(filteredChangedTableList)) {
          for (const table of filteredChangedTableList) {
            queryArr = [
              ...queryArr,
              ...this.generateTableChangeQuery(table, trdMst, dbType),
            ];
          }
          for (const table of changedTableList) {
            //쿼리 순서상 관계 변경은 제일 뒤에 되어야 해서 별도로 만듬
            queryArr = [
              ...queryArr,
              ...this.generateTableRelationChangeQuery(table, trdMst, dbType),
            ];
          }
        }
      } else {
        //완전 신규 생성
        const { tableList } = trdMst;
        let relationList = [];
        for (const table of tableList) {
          if (table.useYn === "N") {
            queryArr.push(this.generateTableDropQuery(table, trdMst, dbType));
          } else {
            const createQuerySet = this.generateTableCreateQuery(table, dbType);
            if (createQuerySet) {
              queryArr.push(createQuerySet);
              relationList.push(table.relation);
            }
          }
        }
        // 관계 추가
        if (!ArrayUtils.isEmpty(relationList)) {
          for (const relations of relationList) {
            for (const relation of relations) {
              if (relation.useYn !== "N") {
                queryArr.push(
                  this.generateForeignKeyQuery(relation, trdMst, dbType)
                );
              }
            }
          }
        }
      }
      return queryArr;
    } catch (error) {
      console.log(error);
      DaafMessage.alert(error.message, "warning");
    }
  };

  /**
   * 테이블 생성 쿼리 메서드
   * @param {*} table
   * @param {*} dbType
   * @returns
   */
  static generateTableCreateQuery = (table, dbType = this.DBType.MySql) => {
    const { tablePhysicalNm, trdTableField } = table;

    //쿼리 데이터 구조 변경
    let QuerySet = {
      ...this.QuerySet,
    };

    let tableCreateQuery = "";
    QuerySet.queryKey = Enums.QueryKey.TABLE.CREATE;
    QuerySet.tableNm = tablePhysicalNm;
    QuerySet.baseTrdMstHistoryId = this.baseTrdMstHistoryId;
    QuerySet.queryComment = `${tablePhysicalNm} 테이블 추가`;

    tableCreateQuery += `/* ${tablePhysicalNm} 테이블 추가 */`;
    tableCreateQuery += this.addLine();

    switch (dbType) {
      case this.DBType.ORACLE:
      case this.DBType.MSSQL:
      case this.DBType.MySql:
        let pkFieldList = [];
        let uniqueFieldList = [];
        //create를 전부 만들고 난 다음에 관계쿼리 생성 목적
        tableCreateQuery += `CREATE TABLE \`${tablePhysicalNm}\` ( ${this.addLine(
          1
        )}`;
        //필드 추가
        for (const [index, field] of trdTableField.entries()) {
          tableCreateQuery += `${this.addTab(3)}`;
          if (index > 0) tableCreateQuery += `, `;
          if (
            StringUtils.equalsIgnoreCase(field.pkYn, "Y") &&
            !StringUtils.equalsIgnoreCase(field.autoIncrementYn, "Y")
          ) {
            pkFieldList.push(this.getFieldNm(field));
          }

          if (StringUtils.equalsIgnoreCase(field.uniqueYn, "Y"))
            uniqueFieldList.push(this.getFieldNm(field));
          //필드 세팅
          tableCreateQuery += this.generateFieldInfoQuery(field, dbType);
          tableCreateQuery += this.addLine();
        }

        //PK 설정 추가
        if (!ArrayUtils.isEmpty(pkFieldList)) {
          tableCreateQuery += `${this.addTab(
            3
          )}, CONSTRAINT \`${tablePhysicalNm}_PK\`  PRIMARY KEY ( `;
          for (const [index, pkField] of pkFieldList.entries()) {
            if (index > 0) tableCreateQuery += " , ";
            tableCreateQuery += ` \`${pkField}\` `;
          }
          tableCreateQuery += ` ) `;
        }

        //Unique 설정 추가
        if (!ArrayUtils.isEmpty(uniqueFieldList)) {
          tableCreateQuery += `${this.addLine(1)}${this.addTab(3)},`;

          //unique key name
          tableCreateQuery += ` UNIQUE KEY `;
          tableCreateQuery += `\`${table.tablePhysicalNm}_un\` `;
          tableCreateQuery += ` ( `;
          for (const [index, uniqueField] of uniqueFieldList.entries()) {
            if (index > 0) tableCreateQuery += " , ";
            tableCreateQuery += `\`${uniqueField}\``;
          }
          tableCreateQuery += " ) ";
        }
        tableCreateQuery += `\n);\n`;
        break;
      default:
        break;
    }
    QuerySet.queryContents = tableCreateQuery;
    return QuerySet;
  };

  /**
   * 테이블 drop 하는 쿼리 생성기
   * @param {*} table
   * @param {*} trd
   * @param {*} dbType
   * @returns
   */
  static generateTableDropQuery = (table, trd, dbType = this.DBType.MySql) => {
    const QuerySet = {
      ...this.QuerySet,
    };

    const { tablePhysicalNm } = table;
    QuerySet.queryKey = Enums.QueryKey.TABLE.DROP;
    QuerySet.tableNm = tablePhysicalNm;
    QuerySet.baseTrdMstHistoryId = this.baseTrdMstHistoryId;
    QuerySet.queryComment = `${tablePhysicalNm} 테이블 삭제`;
    QuerySet.queryContents += `/* ${QuerySet.queryComment} */`;
    QuerySet.queryContents += this.addLine();

    // 삭제될 테이블과 관계를 맺고 있는 fk constraint를 먼저 drop 해야함
    const { tableList } = trd;

    // for (const otherTable of tableList) {
    //   if (
    //     StringUtils.equalsIgnoreCase(
    //       otherTable.tablePhysicalNm,
    //       tablePhysicalNm
    //     )
    //   )
    //     continue;
    //   //삭제하고자 하는 테이블을 targetTable로 하는 relation 검색
    //   const relationWithTable = otherTable.relation.find(
    //     (r) => r.targetTable.tablePhysicalNm === tablePhysicalNm
    //   );
    //   if (!ObjectUtils.isEmpty(relationWithTable)) {
    //     //있으면 constraint 드랍
    //     QuerySet.queryContents += this.generateForeignKeyDropQuery(
    //       otherTable.tablePhysicalNm,
    //       tablePhysicalNm,
    //       dbType
    //     );
    //   }
    // }

    switch (dbType) {
      case this.DBType.ORACLE:
      case this.DBType.MSSQL:
      case this.DBType.MySql:
        QuerySet.queryContents += ` DROP TABLE IF EXISTS ${tablePhysicalNm}; `;
        QuerySet.queryContents += this.addLine(1);
        break;

      default:
        break;
    }

    return QuerySet;
  };

  /**
   * 릴레이션(FK) 쿼리 생성하는 메서트
   * @param {*} relations
   * @param {*} dbType
   */
  static generateForeignKeyQuery = (
    relation,
    trdMst,
    dbType = this.DBType.MySql
  ) => {
    const QuerySet = {
      ...this.QuerySet,
    };

    const { sourceTable, targetTable: relTargetTable, joinColumns } = relation;
    const targetTable = trdMst.tableList.find(
      (t) => t.tablePhysicalNm === relTargetTable.tablePhysicalNm
    );

    QuerySet.queryKey = Enums.QueryKey.RELATION.ADD;
    QuerySet.tableNm = sourceTable.tablePhysicalNm;
    QuerySet.tableNm2 = targetTable.tablePhysicalNm;
    QuerySet.queryComment = `${sourceTable.tablePhysicalNm} - ${targetTable.tablePhysicalNm} 관계 추가`;

    const FKNm =
      relation.relationNm ||
      `fk_${sourceTable.tablePhysicalNm}_to_${targetTable.tablePhysicalNm}`;
    const joinColumnList = [];
    const refColumnList = [];

    //targetTable의 PK 선언 순서와 동일하여야함
    // 그렇기 때문에 Join 선언문을 만들 때 타겟 테이블의 필드 순서대로 toField를 조회해서
    // 해당 필드를 조회한 join 양식이 나오면 그 순서대로 목록화 시킴
    targetTable.trdTableField.forEach((field) => {
      const joinColumn = joinColumns.find((jc) =>
        StringUtils.equalsIgnoreCase(
          this.getFieldNm(jc.toField),
          this.getFieldNm(field)
        )
      );
      if (joinColumn) {
        joinColumnList.push(`\`${this.getFieldNm(joinColumn.fromField)}\``);
        refColumnList.push(`\`${this.getFieldNm(joinColumn.toField)}\``);
      }
    });
    switch (dbType) {
      case this.DBType.ORACLE:
      case this.DBType.MSSQL:
      case this.DBType.MySql:
        QuerySet.queryContents += this.addLine();
        QuerySet.queryContents += ` ALTER TABLE ${this.getDatabaseNm()}.${
          sourceTable.tablePhysicalNm
        } ADD CONSTRAINT \`${FKNm}\` `;
        QuerySet.queryContents += this.addLine();
        QuerySet.queryContents += ` FOREIGN KEY (${joinColumnList.join(",")})`;
        QuerySet.queryContents += this.addLine();
        QuerySet.queryContents += ` REFERENCES ${this.getDatabaseNm()}.${
          targetTable.tablePhysicalNm
        }(${refColumnList.join(",")}); `;
        QuerySet.queryContents += this.addLine();
        if (StringUtils.equalsIgnoreCase(relation.cascadeType, "A")) {
          QuerySet.queryContents += `${this.addTab(2)} ON DELETE CASCADE `;
          QuerySet.queryContents += this.addLine();
          QuerySet.queryContents += `${this.addTab(2)} ON UPDATE CASCADE `;
        } else if (StringUtils.equalsIgnoreCase(relation.cascadeType, "U")) {
          QuerySet.queryContents += `${this.addTab(5)} ON UPDATE CASCADE `;
        } else if (StringUtils.equalsIgnoreCase(relation.cascadeType, "D")) {
          QuerySet.queryContents += `${this.addTab(5)} ON DELETE CASCADE `;
        }

        break;

      default:
        break;
    }

    return QuerySet;
  };

  /**
   * FK 수정하는 쿼리 생성 메서드
   * 기존 관계 삭제후 재생성
   * @param {*} soruceTableNm
   * @param {*} relation
   * @param {*} trdMst
   * @param {*} dbType
   * @returns
   */
  static generateForeignKeyUpdateQuery = (
    soruceTableNm,
    relation,
    trdMst,
    dbType
  ) => {
    const QuerySet = {
      ...this.QuerySet,
    };
    QuerySet.baseTrdMstHistoryId = this.baseTrdMstHistoryId;
    QuerySet.queryKey = Enums.QueryKey.RELATION.DROP;
    QuerySet.tableNm = soruceTableNm;
    QuerySet.tableNm2 = relation.targetTable.tablePhysicalNm;
    QuerySet.queryComment = `${soruceTableNm} - ${relation.relationNm} 관계 수정`;
    QuerySet.queryContents += `/* 수정 전 기존 관계 삭제 */`;
    QuerySet.queryContents += this.addLine();
    QuerySet.queryContents += `ALTER TABLE \`${soruceTableNm}\` DROP FOREIGN KEY \`${relation.relationNm}\`;`;
    QuerySet.queryContents += this.addLine(2);
    QuerySet.queryContents += `/* 새로운 관계 정립 */`;
    QuerySet.queryContents += this.generateForeignKeyQuery(
      relation,
      trdMst,
      dbType
    ).queryContents;
    return QuerySet;
  };

  /**
   * 외래키 Drop 쿼리 생성기
   * @param {*} soruceTableNm
   * @param {*} targetTableNm
   */
  static generateForeignKeyDropQuery = (
    soruceTableNm,
    targetTableNm,
    relationNm,
    dbType = this.DBType.MySql
  ) => {
    const QuerySet = {
      ...this.QuerySet,
    };
    QuerySet.queryKey = Enums.QueryKey.RELATION.DROP;
    QuerySet.tableNm = soruceTableNm;
    QuerySet.tableNm2 = targetTableNm;
    QuerySet.queryComment = `${soruceTableNm} - ${relationNm} 관계 제거`;
    QuerySet.queryContents += this.addLine();
    QuerySet.queryContents += `/* ${QuerySet.queryComment} */`;
    QuerySet.queryContents += this.addLine();

    //있으면 constraint 드랍
    switch (dbType) {
      case this.DBType.MSSQL:
      case this.DBType.ORACLE:
      case this.DBType.MySql:
        QuerySet.queryContents += `ALTER TABLE \`${soruceTableNm}\` DROP FOREIGN KEY \`${relationNm}\`;`;
        QuerySet.queryContents += this.addLine();
        break;

      default:
        break;
    }
    return QuerySet;
  };

  /**
   * 테이블 변경 점 생성 쿼리
   * @param {*} table
   * @param {*} trdMst
   * @param {*} dbType
   */
  static generateTableChangeQuery = (
    table,
    trdMst,
    dbType = this.DBType.MySql
  ) => {
    let QuerySetList = [];
    const {
      tableNm,
      trdTableField: changedField,
      fieldList,
      tablePhysicalNm,
    } = table;

    //테이블 정보가 바뀐 경우

    // 필드가 바뀐 경우
    if (!ObjectUtils.isEmpty(changedField)) {
      const { addedField, deletedField, updatedField } = changedField;
      let addPkDropQuery = false;
      //pk드랍 쿼리 우선 생성
      if (!addPkDropQuery) {
        const isAlreadyExistPk = updatedField.some((f) => {
          return f.changed.pkYn?.prevData === "Y";
        });
        const isAddPk = updatedField.some((f) => {
          return (
            f.changed.pkYn?.prevData === "N" &&
            f.changed.pkYn?.currentData === "Y"
          );
        });
        //기존에 PK가 있는 경우에만 PK를 드랍한다.
        if (isAlreadyExistPk || isAddPk) {
          QuerySetList.splice(0, 0, this.generatePkDropQuery(tableNm));
        }
        addPkDropQuery = true;
      }

      //필드 추가
      for (const [index, field] of addedField.entries()) {
        const QuerySet = this.generateAddColumnQuery(field, tableNm, dbType);
        QuerySetList.push(QuerySet);
      }
      //필드 수정
      if (!ArrayUtils.isEmpty(updatedField)) {
        switch (dbType) {
          case this.DBType.MSSQL:
          case this.DBType.ORACLE:
          case this.DBType.MySql:
            QuerySetList = [
              ...QuerySetList,
              ...this.generateFieldListUpdateQuery({
                updatedField,
                tableNm,
                currentFieldList: fieldList,
                dbType,
              }),
            ];

            break;

          default:
            break;
        }
      }

      //필드 삭제
      if (!ArrayUtils.isEmpty(deletedField)) {
        for (const field of deletedField) {
          QuerySetList.push(this.generateDropColumnQuery(field, tableNm));
        }
      }
    }

    //테이블 명 변경
    if (!ObjectUtils.isEmpty(tablePhysicalNm)) {
      QuerySetList.push(this.generateTableRenameQuery(tablePhysicalNm, dbType));
    }

    return QuerySetList;
  };

  /**
   * 테이블 명 바꾸는 쿼릿 생성
   * @param {*} param0
   * @param {*} dbType
   */
  static generateTableRenameQuery = ({ prevData, currentData }, dbType) => {
    const QuerySet = {
      ...this.QuerySet,
    };
    QuerySet.baseTrdMstHistoryId = this.baseTrdMstHistoryId;
    QuerySet.queryKey = "ALTER_TABLE_NAME";
    QuerySet.queryComment = `${prevData} 테이블 명 변경`;
    QuerySet.queryContents += `/* ${QuerySet.queryComment} */`;
    QuerySet.queryContents += this.addLine();
    QuerySet.tableNm = prevData;

    switch (dbType) {
      case this.DBType.MSSQL:
      case this.DBType.MySql:
      case this.DBType.ORACLE:
        QuerySet.queryContents += `ALTER TABLE ${prevData} RENAME TO ${currentData};`;
        break;
      default:
        break;
    }
    return QuerySet;
  };

  /**
   * 칼럼 추가하는 쿼리 만드는 함수
   * @param {*} field
   * @param {*} tableNm
   * @param {*} dbType
   * @returns
   */
  static generateAddColumnQuery = (
    field,
    tableNm,
    dbType = this.DBType.MySql
  ) => {
    const QuerySet = {
      ...this.QuerySet,
    };
    QuerySet.queryKey = Enums.QueryKey.COLUMN.ADD;
    QuerySet.fieldNm = this.getFieldNm(field);
    QuerySet.tableNm = tableNm;
    QuerySet.baseTrdMstHistoryId = this.baseTrdMstHistoryId;
    QuerySet.queryComment = `${tableNm} 테이블 ${this.getFieldNm(
      field
    )} 컬럼 추가`;
    QuerySet.queryContents += `/* ${QuerySet.queryComment} */`;
    QuerySet.queryContents += this.addLine();
    QuerySet.queryContents += `ALTER TABLE \`${tableNm}\``;
    QuerySet.queryContents += ` ADD COLUMN `;
    QuerySet.queryContents += this.generateFieldInfoQuery(field, dbType);
    QuerySet.queryContents += ";";

    return QuerySet;
  };

  /**
   * 컬럼 삭제하는 쿼리 생성하는 함수
   * @param {*} field
   * @param {*} tableNm
   * @returns
   */
  static generateDropColumnQuery = (field, tableNm) => {
    const QuerySet = {
      ...this.QuerySet,
    };
    QuerySet.queryKey = Enums.QueryKey.COLUMN.DROP;
    QuerySet.tableNm = tableNm;
    QuerySet.fieldNm = this.getFieldNm(field);
    QuerySet.baseTrdMstHistoryId = this.baseTrdMstHistoryId;
    QuerySet.queryComment = `${tableNm} 테이블 ${this.getFieldNm(
      field
    )} 컬럼 삭제 `;
    QuerySet.queryContents += this.addLine();
    QuerySet.queryContents = `/* ${QuerySet.queryComment} */`;
    QuerySet.queryContents += this.addLine();
    QuerySet.queryContents += `ALTER TABLE \`${tableNm}\``;
    QuerySet.queryContents += this.addLine();
    QuerySet.queryContents += this.addTab(2);
    QuerySet.queryContents += ` DROP COLUMN \`${this.getFieldNm(field)}\`;`;

    return QuerySet;
  };

  /**
   * 테이블 관계 변경점 생성 쿼리
   * 쿼리 순서상 제일 뒤쪽에 있어야 해서 별도로 만듬
   * @param {*} table
   * @param {*} trdMst
   * @param {*} dbType
   */
  static generateTableRelationChangeQuery = (table, trdMst, dbType) => {
    let QuerySetList = [];

    const { tableNm, relation } = table;
    //각 관계는 relationNm을 가지고 있다.
    if (!ObjectUtils.isEmpty(relation)) {
      const { addedRelation, updatedRelation, deletedRelation } = relation;
      if (!ArrayUtils.isEmpty(deletedRelation)) {
        deletedRelation.forEach((delRel) => {
          QuerySetList.push(
            this.generateForeignKeyDropQuery(
              tableNm,
              delRel.targetTable.tablePhysicalNm,
              delRel.relationNm
            )
          );
        });
      }
      if (!ArrayUtils.isEmpty(addedRelation)) {
        for (const addRelation of addedRelation) {
          QuerySetList.push(
            this.generateForeignKeyQuery(addRelation, trdMst, dbType)
          );
        }
      }
      if (!ArrayUtils.isEmpty(updatedRelation)) {
        for (const updateRel of updatedRelation) {
          QuerySetList.push(
            this.generateForeignKeyUpdateQuery(
              tableNm,
              updateRel,
              trdMst,
              dbType
            )
          );
        }
      }
    }

    return QuerySetList;
  };

  /**
   * 필드 정보 적는 쿼리 생성기
   * ALTER COLUMN 또는 Create Table 등에 사용
   * @param {*} field
   * @returns
   */
  static generateFieldInfoQuery = (field, dbType) => {
    let fieldQuery = "";
    fieldQuery += `\`${this.getFieldNm(field)}\``;
    fieldQuery += `${this.addTab(1)}${this.getDataType(
      field,
      dbType
    )}${this.getDataLength(field)}`;
    if (StringUtils.equalsIgnoreCase(field.notNullYn, "Y")) {
      fieldQuery += this.addTab(1);
      fieldQuery += `NOT NULL`;
    }
    if (StringUtils.equalsIgnoreCase(field.autoIncrementYn, "Y")) {
      fieldQuery += this.addTab(1);
      fieldQuery += `AUTO_INCREMENT PRIMARY KEY`;
    }
    if (!StringUtils.isEmpty(field.fieldDefaultValue)) {
      fieldQuery += this.addTab();
      fieldQuery += `DEFAULT ${this.getDefaultValue(field, dbType)}`;
    }

    if (!StringUtils.isEmpty(field.extra)) {
      fieldQuery += this.addTab();
      fieldQuery += `${field.extra} `;
    }

    if (!StringUtils.isEmpty(this.getFieldComment(field))) {
      fieldQuery += this.addTab();
      fieldQuery += `COMMENT '${this.getFieldComment(field)}' `;
    }
    return fieldQuery;
  };

  /**
   * 컬럼 업데이트 된 쿼리 생성(ALTER COLUMN) 함수
   * @param {Object} props
   * @param {String} props.tableNm
   * @param {Array} props.updatedField
   * @param {Array} props.currentFieldList
   * @param {String} props.dbType
   */
  static generateFieldListUpdateQuery = ({
    tableNm,
    updatedField,
    currentFieldList,
    dbType,
  }) => {
    let pkChanged = false;
    //유니크 키 변경 여부 - 쿼리 생성 마지막에 활용
    let isUniqueKeyChanged = false;
    //쿼리 생성
    const QuerySetList = [];
    for (const [index, field] of updatedField.entries()) {
      //특수하게 수정된 경우
      //pk 와 unique만 바뀐 경우에는 별도로 수정 컬럼을 만들지 않는다.
      let queryGenerated = false;
      const { changed } = field;

      for (const fieldKey in changed) {
        // PK 값이 바뀐 경우
        // PK 변경이 있는 경우는 기존 PK를 드랍하고 새롭게 생성한다.
        if (StringUtils.equalsIgnoreCase(fieldKey, "pkYn")) {
          if (!pkChanged) {
            pkChanged = true;
          }
        }
        // Unique 값이 바뀐 경우
        else if (StringUtils.equalsIgnoreCase(fieldKey, "uniqueYn")) {
          //
          isUniqueKeyChanged = true;
        } else if (!queryGenerated) {
          let QuerySet = this.generateFieldUpdateQuery({
            dbType,
            field,
            tableNm,
          });
          QuerySetList.push(QuerySet);
          queryGenerated = true;
        }
      }
    }

    //pK 변경이 있는 경우
    if (pkChanged) {
      const pkResult = this.generateSetPKColumn({
        fieldList: currentFieldList,
        tableNm,
        dbType,
      });
      if (pkResult) QuerySetList.push(pkResult);
    }

    if (isUniqueKeyChanged) {
      //기존에 UN이 있는 경우에만 UN을 드랍한다.
      const isAlreadyExistUnique = updatedField.some((f) => {
        return f.changed.uniqueYn?.prevData === "Y";
      });
      QuerySetList.push(
        this.generateSetUniqueColumn({
          fieldList: currentFieldList,
          uniqueInit: isAlreadyExistUnique,
          tableNm,
          dbType,
        })
      );
    }
    return QuerySetList;
  };
  /**
   * 컬럼 업데이트 쿼리 생성 함수
   * @param {Object} props
   * @param {Object} props.field
   * @param {String} props.tableNm
   * @param {String} props.dbType
   * @returns
   */
  static generateFieldUpdateQuery = ({ field, tableNm, dbType }) => {
    const QuerySet = {
      ...this.QuerySet,
    };
    QuerySet.queryKey = Enums.QueryKey.COLUMN.ALTER;
    QuerySet.fieldNm = field.fieldNm;
    QuerySet.tableNm = tableNm;
    QuerySet.baseTrdMstHistoryId = this.baseTrdMstHistoryId;
    QuerySet.queryComment = `${tableNm} 테이블의 ${field.fieldNm} 컬럼 수정 `;
    QuerySet.queryContents += `/* ${QuerySet.queryComment} */`;
    QuerySet.queryContents += this.addLine();
    QuerySet.queryContents += `ALTER TABLE ${tableNm} `;
    QuerySet.queryContents += this.addLine();
    QuerySet.queryContents += `${this.addTab(2)} MODIFY COLUMN \`${
      field.fieldNm
    }\` `;
    QuerySet.queryContents += `${this.getDataType(
      field.fieldInfo,
      dbType
    )}${this.getDataLength(field.fieldInfo)} `;

    //NOT NUL YN 여부
    if (StringUtils.equalsIgnoreCase(field.fieldInfo.notNullYn, "Y")) {
      QuerySet.queryContents += `NOT `;
    }
    QuerySet.queryContents += `NULL `;
    //자동 증가 여부
    if (StringUtils.equalsIgnoreCase(field.fieldInfo.autoIncrementYn, "Y")) {
      QuerySet.queryContents += ` AUTO_INCREMENT PRIMARY KEY `;
    }

    if (!StringUtils.isEmpty(this.getDefaultValue(field.fieldInfo, dbType))) {
      QuerySet.queryContents += `DEFAULT ${this.getDefaultValue(
        field.fieldInfo,
        dbType
      )} `;
    }

    if (!StringUtils.isEmpty(field.fieldInfo.extra)) {
      QuerySet.queryContents += `${field.fieldInfo.extra} `;
    }

    if (!StringUtils.isEmpty(this.getFieldComment(field.fieldInfo))) {
      QuerySet.queryContents += `COMMENT '${this.getFieldComment(
        field.fieldInfo
      )}' `;
    }
    QuerySet.queryContents += ";";
    return QuerySet;
  };

  /**
   * PK 쿼리 생성 함수
   * @param {Object} props
   * @param {Array} props.fieldList
   * @param {String} props.tableNm
   * @param {String} props.dbType
   * @param {Boolean} props.pkInit
   * @returns
   */
  static generateSetPKColumn = ({ fieldList, tableNm, dbType }) => {
    const pkField = fieldList.filter(
      (f) =>
        StringUtils.equalsIgnoreCase(f.pkYn, "Y") &&
        StringUtils.equalsIgnoreCase(f.autoIncrementYn, "N")
    );
    if (ArrayUtils.isEmpty(pkField)) return;
    const QuerySet = {
      ...this.QuerySet,
    };
    QuerySet.queryKey = Enums.QueryKey.COLUMN.PK;
    QuerySet.tableNm = tableNm;
    QuerySet.baseTrdMstHistoryId = this.baseTrdMstHistoryId;
    QuerySet.queryComment = `${tableNm} - PK 세팅`;
    QuerySet.queryContents += this.addLine();

    if (!ArrayUtils.isEmpty(pkField)) {
      QuerySet.queryContents += `/* PK 새롭게 세팅 */`;
      QuerySet.queryContents += this.addLine();
      QuerySet.queryContents += `ALTER TABLE ${tableNm} ADD CONSTRAINT PRIMARY KEY (`;
      for (const [index, uField] of pkField.entries()) {
        if (index > 0) QuerySet.queryContents += " , ";
        QuerySet.queryContents += `\`${this.getFieldNm(uField)}\``;
      }
      QuerySet.queryContents += `);`;
    }
    return QuerySet;
  };

  /**
   * Table의 PK Drop 하는 쿼리 생성메서드
   * @param {*} tableNm
   */
  static generatePkDropQuery = (tableNm) => {
    const QuerySet = {
      ...this.QuerySet,
    };
    QuerySet.queryKey = Enums.QueryKey.COLUMN.DROP_PK;
    QuerySet.tableNm = tableNm;
    QuerySet.baseTrdMstHistoryId = this.baseTrdMstHistoryId;
    QuerySet.queryComment = `${tableNm} - PK 변경으로 인한 기존 PK 삭제 `;
    QuerySet.queryContents += `/* PK 변경으로 인한 기존 PK 삭제 */`;
    QuerySet.queryContents += this.addLine();
    QuerySet.queryContents += `ALTER TABLE \`${tableNm}\` DROP PRIMARY KEY;`;

    return QuerySet;
  };

  /**
   * Unique 컬럼 쿼리 생성 함수
   * @param {Object} props
   * @param {Array} props.fieldList
   * @param {String} props.tableNm
   * @param {String} props.dbType
   * @param {Boolean} props.uniqueInit
   * @returns
   */
  static generateSetUniqueColumn = ({
    fieldList,
    tableNm,
    dbType,
    uniqueInit,
  }) => {
    const uniqueFields = fieldList.filter((f) =>
      StringUtils.equalsIgnoreCase(f.uniqueYn, "Y")
    );
    const QuerySet = {
      ...this.QuerySet,
    };
    QuerySet.queryKey = Enums.QueryKey.COLUMN.UNIQUE;
    QuerySet.tableNm = tableNm;
    QuerySet.baseTrdMstHistoryId = this.baseTrdMstHistoryId;
    QuerySet.queryComment = `${tableNm} - Unique Key Set `;
    if (uniqueInit) {
      QuerySet.queryContents += `/* 유니크 키 변경으로 인한 기존 유니크 삭제 */`;
      QuerySet.queryContents += this.addLine();
      QuerySet.queryContents += `ALTER TABLE \`${tableNm}\` DROP INDEX ${tableNm}_un;`;
    }

    QuerySet.queryContents += this.addLine();
    if (!ArrayUtils.isEmpty(uniqueFields)) {
      QuerySet.queryContents += `/* 유니크 키 새롭게 세팅 */`;
      QuerySet.queryContents += this.addLine();
      QuerySet.queryContents += `ALTER TABLE ${tableNm} ADD CONSTRAINT \`${tableNm}_un\` UNIQUE (`;

      for (const [index, uField] of uniqueFields.entries()) {
        if (index > 0) QuerySet.queryContents += " , ";
        QuerySet.queryContents += `\`${this.getFieldNm(uField)}\``;
      }
      QuerySet.queryContents += `);`;
    }
    return QuerySet;
  };

  /**
   * 서버에서 가져온 데이터를 TRD에 맞게 바꾸는 로직
   * 바꾸는 로직만 실행하기 때문에 기존 TRD에 있는 테이블과의 merge 작업은 redux에서 실행
   * @param {*} trd
   * @param {*} serverTableList
   * @param {*} elementList
   * @returns
   */
  static convertTableData = ({
    trd,
    serverTableList,
    elementList,
    appEnvId,
  }) => {
    /**
     * 한줄에 10개씩 테이블 만들예정
     * 2번째 줄 부터는 첫줄의 각 열에 해당하는 테이블을 찾아서 포지션을 설정해야 하기 때문에
     * 각 테이블의 포지션 정보와 사이즈를 기억시켜둘 예정
     */
    const positionList = [];

    const newTrdInfo = {
      ...trd.info,
      appEnvId,
    };
    const areaList = trd.areaList;

    /**
     * 테이블 정보 생성시 영역 설정하는 함수
     * @param {*} tableInfo
     */
    const getTrdArea = (tableInfo) => {
      if (newTrdInfo.trdAreaType === "S") {
        //영역이 싱글일때
        tableInfo.trdArea = areaList[0];
      } else {
        //영역이 멀티일때
        const commonArea = areaList.find((a) => a.sectorType === "C");
        for (const area of areaList) {
          const { areaCriteria, areaAffixType } = area;
          if (StringUtils.equalsIgnoreCase(areaAffixType, "BE")) {
            //prefix
            if (
              tableInfo.tablePhysicalNm
                .toLowerCase()
                .startsWith(areaCriteria.toLowerCase())
            ) {
              tableInfo.trdArea = area;
            }
          } else if (StringUtils.equalsIgnoreCase(areaAffixType, "ED")) {
            //suffix
            if (
              tableInfo.tablePhysicalNm
                .toLowerCase()
                .endsWith(areaCriteria.toLowerCase())
            ) {
              tableInfo.trdArea = area;
            }
          } else if (StringUtils.equalsIgnoreCase(areaAffixType, "CT")) {
            //포함
            if (
              StringUtils.indexOf(
                tableInfo.tablePhysicalNm.toLowerCase(),
                areaCriteria.toLowerCase()
              ) > -1
            ) {
              tableInfo.trdArea = area;
            }
          }
        }
        if (ObjectUtils.isEmpty(tableInfo.trdArea))
          tableInfo.trdArea = commonArea;
      }
    };

    /**
     * 필드 설정시 Element 검색 및 정의하는 함수
     * @param {*} column
     * @returns
     */
    const getElement = ({ columnName, columnComment, columnType }) => {
      const element = elementList.find((e) => e.elementCd === columnName);
      if (element) {
        return element;
      } else {
        return null;
      }
    };

    const tableSize = {
      width: 500,
    };

    const tableInterval = {
      x: 100,
      y: 100,
    };

    const tablePosition = {
      x: 60,
      y: 0,
    };

    const tableList = serverTableList.map((table, index) => {
      const obj = {};

      obj.appEnvId = appEnvId;
      obj.tableMstId = StringUtils.getUuid();
      obj.tableLogicalNm = table.tableComment;
      obj.tablePhysicalNm = table.tableName;
      obj.tableType = table.entityType;
      obj.tableDesc = "";
      obj.tableDeliveryClass = "A";
      obj.tableViewMaint = "A";
      obj.useYn = "Y";
      getTrdArea(obj);
      // 필드값 적용
      obj.trdTableField = table.columns.map((col) => {
        const field = {};
        field.fieldId = StringUtils.getUuid();
        field.autoIncrementYn = StringUtils.equalsIgnoreCase(
          col.autoIncrementYn,
          "Y"
        )
          ? "Y"
          : "N";
        field.pkYn = StringUtils.equalsIgnoreCase(col.pkYn, "Y") ? "Y" : "N";
        field.uniqueYn = StringUtils.equalsIgnoreCase(col.uniqueYn, "Y")
          ? "Y"
          : "N";
        field.fieldDefaultValue = col.columnDefault || "";
        field.notNullYn = StringUtils.equalsIgnoreCase(col.requiredYn, "Y")
          ? "Y"
          : "N";
        field.extra = col.extra;
        field.refTableId = null;
        field.refFieldId = null;
        field.logicalNm = StringUtils.defaultString(
          col.columnComment,
          col.columnName
        );
        field.physicalNm = col.columnName;
        field.element = getElement(col);
        field.sortSeq = col.ordinalPosition;
        return field;
      });

      //포지션 설정

      //줄바꿈
      const currentRow = Math.floor(index / 20);
      const currentCol = index % 20;
      if (ArrayUtils.isEmpty(positionList[currentRow])) {
        positionList[currentRow] = [];
      }

      positionList[currentRow][currentCol] = {};

      obj.position = {
        x: tablePosition.x + (tableSize.width + tableInterval.x) * currentCol,
        y: 0,
      };
      if (currentRow > 0) {
        obj.position.y =
          positionList[currentRow - 1][currentCol].y +
          positionList[currentRow - 1][currentCol].height +
          tableInterval.y;
      }
      positionList[currentRow][currentCol] = {
        ...obj.position,
        height: obj.trdTableField.length * 30 + 35 + 30,
      };
      obj.relation = table.relation;
      return obj;
    });

    //관계 설정
    for (const table of tableList) {
      if (!ArrayUtils.isEmpty(table.relation)) {
        const relationObj = {
          relationType: "N",
          cascadeType: "N",
        };
        const relations = [];
        for (const rel of table.relation) {
          const { relation, ...sourceTable } = table;
          const { targetTable: targetTableNm, joinColumns } = rel;
          const targetTable = tableList.find(
            (t) => t.tablePhysicalNm === targetTableNm
          );
          if (!targetTable) continue;

          relationObj.sourceTable = sourceTable;
          relationObj.targetTable = targetTable;
          relationObj.joinColumns = joinColumns.map((jc) => {
            const col = {};

            const fromField = table.trdTableField.find(
              (f) => f.fieldNm === jc.physicalNm
            );
            const toField = targetTable.trdTableField.find(
              (f) => f.fieldNm === jc.physicalNm
            );
            col.fromField = fromField;
            col.toField = toField;
            return col;
          });
          relations.push(relationObj);
        }
        table.relation = relations;
      } else {
        table.relation = [];
      }
    }

    return {
      info: newTrdInfo,
      areaList,
      tableList,
      activate: trd.activate,
    };
  };

  /**
   * Table Field의 physical 명을 가져오는 로직
   * @param {*} field
   * @returns
   */
  static getFieldPhysicalNm = (field) => {
    let physicalNm = field.physicalNm;
    if (field.element) {
      physicalNm = field.element.elementCd;
    }

    return physicalNm;
  };

  /**
   * Table Field의 logical 명을 가져오는 로직
   * @param {*} field
   * @returns
   */
  static getFieldLogicalNm = (field) => {
    let logicalNm = field.logicalNm;
    if (field.element) {
      logicalNm = field.element.elementNm;
    }

    return logicalNm;
  };

  /**
   * 생성된 Element를 Trd 전체에 적용함
   * 적용할때 대상 컬럼명이 같고 적용된 Element가 없어야 함
   * @param {*} element
   * @param {*} trd
   * @param {*} dispatch
   */
  static applyElement = (element, trd, dispatch) => {
    const newTrd = produce(trd, (draft) => {
      draft.tableList = trd.tableList.map((table) => {
        const obj = { ...table };
        obj.trdTableField = obj.trdTableField.map((field) => {
          const fObj = { ...field };
          if (
            StringUtils.equalsIgnoreCase(fObj.physicalNm, element.elementCd) &&
            !fObj.element
          ) {
            fObj.element = element;
          }
          return fObj;
        });
        return obj;
      });
    });
    DaafMessage.alert("적용 되었습니다.", "success");
    TrdReduxHelper.patchTrd(dispatch, newTrd, trd);
    return newTrd;
  };
}

export default TrdUtil;
