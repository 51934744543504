import {
  ArrayUtils,
  CommonUtils,
  DaafEnums,
  DaafMessage,
  DaafPopup,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import DForm from "components/common/form/Form";
import TableRegisterPopup from "components/popup/trd/TableRegisterPopup";
import TableRelationListPopup from "components/popup/trd/TableRelationListPopup";
import { memo, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlineApi, AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { BiNotepad } from "react-icons/bi";
import {
  MdCheck,
  MdEdit,
  MdErrorOutline,
  MdOutlineCancel,
  MdUnfoldLess,
  MdUnfoldMore,
} from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Handle, NodeResizer, Position, useStore } from "reactflow";
import { selectTrd } from "store/actions/TrdAction";
import { stopEvent } from "utils/CommonUtils";
import TrdUtil from "utils/TrdUtils";
import { ErdPopupSize } from "../TrdBuilder";
import TrdReduxHelper from "../render/TrdReduxHelper";
import TrdRenderUtils from "../render/TrdRenderUtils";
import useAppEnv from "utils/hook/useAppEnv";
import TrdService from "services/TrdService";
import { LuRefreshCw } from "react-icons/lu";
import SimplePopup from "components/popup/SimplePopup";
import Grid from "components/grid/Grid";

export const TrdTableType = memo(({ data, id, selected, ...props }) => {
  const dispatch = useDispatch();
  const trd = useSelector((state) => state.trd);
  const zoom = useStore((s) => s.transform[2]);
  //Memo
  const [isMemoExpand, setIsMemoExpand] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const memoRef = useRef();
  /**
   * 테이블 보기 상세(더블클릭)
   * @param {*} e
   */
  const onDetailTableConfig = (e) => {
    stopEvent(e);
    if (trd.initReverseEngineering) {
      return DaafMessage.alert(
        "테이블 호출로 불러온 경우 최초 저장 후 사용 가능합니다.",
        "info"
      );
    }
    let tempTrd = { ...trd };

    const callback = (info, changeNode = []) => {
      const dupNode = JsonUtils.findNode(
        tempTrd.areaList,
        "tablePhysicalNm",
        info.tablePhysicalNm
      );
      if (dupNode.trdMstId !== info.trdMstId) {
        return DaafMessage.alert(
          "이미 등록된 테이블 입니다.",
          DaafEnums.MessageType.WARN
        );
      } else {
        //테이블 명이 바뀌는 경우

        if (data.tablePhysicalNm !== info.tablePhysicalNm) {
          //info 테이블의 relation 정보 업데이트
          info.relation = info.relation.map((rel) => {
            const soruce = { ...info };
            delete soruce.relation;
            delete soruce.trdTableField;
            rel.sourceTable = soruce;
            return rel;
          });
          changeNode = [
            ...changeNode,
            ...updateRelationTargetTable(data, info),
          ];
        }
        //테이블 내용만 바뀌는 경우
        TrdReduxHelper.updateNodes(dispatch, [info, ...changeNode], tempTrd);
        DaafPopup.close();
      }
    };

    const applyElement = (element) => {
      tempTrd = TrdUtil.applyElement(element, tempTrd, dispatch);
    };

    DaafPopup.open(
      <TableRegisterPopup
        area={data.trdArea}
        trd={trd}
        tableInfo={data}
        callback={callback}
        applyElement={applyElement}
      />,
      {
        style: { content: { width: ErdPopupSize.Register } },
      }
    );
  };

  /**
   * 관계설정된 타겟테이블을 수정하는 함수
   * @param {*} tableInfo
   * @param {*} updatedTable
   */
  const updateRelationTargetTable = (tableInfo, updatedTable) => {
    let willChangeNode = trd.tableList.filter((table) => {
      return table.relation.find((rel) => {
        return rel.targetTable.tablePhysicalNm === tableInfo.tablePhysicalNm;
      });
    });

    const target = { ...updatedTable };
    delete target.relation;
    delete target.trdTableField;

    willChangeNode = willChangeNode.map((table) => {
      const tObj = { ...table };
      tObj.relation = table.relation.map((rel) => {
        const obj = { ...rel };
        if (rel.targetTable.tablePhysicalNm === tableInfo.tablePhysicalNm) {
          obj.targetTable = target;
        }
        return obj;
      });
      return tObj;
    });
    return willChangeNode;
  };

  /**
   * 메모 펼치기
   * @param {*} e
   */
  const onExpandNote = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setIsMemoExpand(!isMemoExpand);
  };

  /**
   * 테이블 삭제
   */
  const onClickRemove = async () => {
    //삭제되기 전 import 되고 있는 trd 검색
    debugger;
    const importInfoResult = await TrdService.getTableImportInfo({
      importTableId: data.tableMstId,
      disableLoad: true,
    });
    if (importInfoResult.isError)
      return DaafMessage.alert(
        "Error Occured getTableImportInfo API ",
        "error"
      );
    if (!ArrayUtils.isEmpty(importInfoResult.data)) {
      const trdList = importInfoResult.data.map((table) => table.trdMst);

      return DaafPopup.open(
        <SimplePopup title="사용중인 TRD 목록">
          <Row className="mb-1">
            <Col>
              아래 TRD에서 테이블이 사용되고 있어서 삭제 할 수 없습니다.
            </Col>
          </Row>
          <Grid
            columns={[
              {
                field: "trdNm",
                headerName: "TRD 명",
                style: { width: "200px" },
              },
              { field: "updtUserId", headerName: "최종 수정자" },
              {
                field: "updtDt",
                headerName: "최종 수정일",
                renderCell: (data) => {
                  return <>{CommonUtils.getDate(data.updtDt)}</>;
                },
              },
              {
                field: "updtUserId",
                headerName: "저장 후 이동",
                renderCell: (data) => {
                  return (
                    <Button
                      size="sm"
                      onClick={(e) =>
                        DaafMessage.alert("개발 예정입니다.", "info")
                      }
                    >
                      <MdEdit />
                      편집
                    </Button>
                  );
                },
              },
            ]}
            rows={trdList}
          />
        </SimplePopup>,
        { style: { content: { width: "650px" } } }
      );
    }

    const relationUsed = trd.tableList.find(
      (table) =>
        table.useYn !== "N" &&
        table.relation.findIndex(
          (r) => r.targetTable.tableMstId === data.tableMstId && r.useYn !== "N"
        ) > -1
    );

    //연결된 릴레이션이 있으면 안됨
    if (!ObjectUtils.isEmpty(relationUsed)) {
      DaafMessage.alert(
        "해당 테이블에 연결된 참조 속성을 제거 후 시도해주세요.",
        "error"
      );
    } else {
      TrdReduxHelper.deleteTable(dispatch, data, trd);
    }
  };

  /**
   * 관계 설정 하는 팝업 오픈
   */
  const onClickRelation = () => {
    /**
     * 리스트 팝업 콜백
     * @param {*} relationList
     */
    const listCallback = (relations) => {
      const changedNodes = [];
      // relations과 data.relation과 비교해서 삭제될것들은 별도로 처리하여 클라단에서 삭제

      //비식별 관계 용은 별도로 구분해서 TargetTable에 필드 추가해야함
      const nonIdentifyingRel = relations.filter((r) => r.relationType === "I");
      /**
       * 1. 소스테이블 수정
       * 2. 타겟테이블 필드 추가 여부 확인 후 필드 추가
       * 3. 적용
       */
      //1. 소스 테이블 수정
      const sourceTable = { ...data };
      sourceTable.relation = relations;
      changedNodes.push(sourceTable);
      for (const table of trd.tableList) {
        let targetTable = null;
        const targetTableRel = nonIdentifyingRel.filter(
          (nr) => nr.props?.targetTable.tableMstId === table.tableMstId
        );
        if (!ArrayUtils.isEmpty(targetTableRel)) {
          for (const rel of targetTableRel) {
            const {
              props: { keyColumns },
            } = rel;
            targetTable = TrdRenderUtils.setIdentifyingKeyColumn(
              keyColumns,
              table
            );
          }
          changedNodes.push(targetTable);
        }
      }
      TrdReduxHelper.updateNodes(dispatch, changedNodes, trd);
      DaafPopup.close();
    };
    DaafPopup.open(
      <TableRelationListPopup
        trd={trd}
        table={data}
        // relationCallback={relationCallback}
        callback={listCallback}
      />,
      {
        style: { content: { width: "550px" } },
      }
    );
  };

  /**
   * 엔티티 접기
   * @param {*} e
   * @param {*} fold
   */
  const onClickFold = (e, fold) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  /**
   *
   * @param {React.MouseEvent} e
   */
  const onClickImportTableRefresh = (e) => {
    e.preventDefault();
    e.stopPropagation();
    DaafMessage.confirm("테이블 정보를 갱신합니다.", () => {
      TrdReduxHelper.refreshImportTable(dispatch, data, trd);
    });
  };

  const renderMemoComponent = () => {
    return (
      <div
        className={`entity-memo ${isMemoExpand ? "" : "hidden"}`}
        onClick={stopEvent}
      >
        <div className="header">
          <button
            onClick={(e) => {
              stopEvent(e);
              setIsMemoExpand(false);
            }}
          >
            <AiOutlineClose />
          </button>
        </div>
        <div className="description-wrapper">
          {isEditMode ? (
            <textarea
              className="description nodrag"
              defaultValue={data.memo?.description || ""}
              ref={memoRef}
              // onBlur={onBlurDescription}
              onClick={stopEvent}
              onScroll={stopEvent}
              onScrollCapture={stopEvent}
            />
          ) : (
            <div
              className="description"
              onClick={stopEvent}
              onDoubleClick={(e) => {
                setIsEditMode(true);
              }}
            >
              {data.memo?.description || ""}
            </div>
          )}
        </div>
        <div className="button-wrapper">
          <div className="memo-info">
            <span>{data.memo?.author || ""}</span>
            <span>{CommonUtils.getDate(data.memo?.time)}</span>
          </div>
          <div>
            {isEditMode ? (
              <>
                <Button
                  size={"sm"}
                  variant="outline-primary"
                  // onClick={onConfirmMemo}
                >
                  확인
                </Button>
                <Button
                  size={"sm"}
                  style={{ marginLeft: "3px" }}
                  variant="outline-danger"
                  onClick={(e) => setIsEditMode(false)}
                >
                  취소
                </Button>
              </>
            ) : (
              <Button
                size={"sm"}
                variant="outline-success"
                onClick={(e) => {
                  stopEvent(e);
                  setIsEditMode(true);
                }}
              >
                수정
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderToolbar = () => {
    return (
      <div className={`erd-toolbar ${false ? "mini" : ""}`}>
        <span />
        {renderMemoComponent()}
        <div className="side right">
          <span onClick={onExpandNote}>
            <BiNotepad size={20 / zoom < 10 ? 15 : 20 / zoom} />
          </span>
          <span onClick={onClickRemove}>
            <RiDeleteBin6Line size={20 / zoom < 10 ? 15 : 20 / zoom} />
          </span>
          <span onClick={onClickRelation}>
            <AiOutlineApi size={20 / zoom < 10 ? 15 : 20 / zoom} />
          </span>
          {data?.importTableId && (
            <span onClick={onClickImportTableRefresh}>
              <LuRefreshCw size={20 / zoom < 10 ? 15 : 20 / zoom} />
            </span>
          )}
          {data.fold ? (
            <span onClick={(e) => onClickFold(e, data.fold ? false : true)}>
              <MdUnfoldMore size={20 / zoom < 10 ? 15 : 20 / zoom} />
            </span>
          ) : (
            <span onClick={(e) => onClickFold(e, data.fold ? false : true)}>
              <MdUnfoldLess size={20 / zoom < 10 ? 15 : 20 / zoom} />
            </span>
          )}
        </div>
      </div>
    );
  };

  /**
   * 컬럼(필드) 렌더링 하는 함수
   * PK 필드를 위로 보냄
   */
  const renderField = () => {
    return data.trdTableField.map((column, index) => {
      const isExistElement =
        column.element && column.element.elementId !== undefined ? true : false;
      const isFk = column.relationFrom ? true : false;
      const keyName = column.element?.elementCd || column.physicalNm;
      return (
        <div className="body-row" key={keyName + index}>
          <div className="cell">{column.pkYn === "Y" ? "🔑" : ""}</div>
          <div className="cell">
            {!isExistElement && (
              <DForm.Tooltip title={"Element 설정 필요"}>
                <span
                  className="warning-text blink"
                  style={{ marginRight: "5px" }}
                >
                  <MdErrorOutline />
                </span>
              </DForm.Tooltip>
            )}
            {isExistElement ? column.element.elementCd : column.physicalNm}
            {isFk && ` (FK)`}
          </div>
          <div className="cell">
            {isExistElement ? column.element.elementNm : column.logicalNm}
          </div>
          <div className="cell">
            {StringUtils.toUpperCase(column.element?.domain?.dataType)}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {selected && renderToolbar()}
      <div
        className={`erd-table-wrapper ${selected ? "selected" : ""} ${
          data?.importTableId ? "imported" : ""
        }`}
        onDoubleClick={onDetailTableConfig}
      >
        {zoom < 0.5 ? (
          <div className="mini-zoom-title">
            <div className="table-name">
              {data.tableLogicalNm || data.tablePhysicalNm}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="toolbar-button"></div>
        <div className="title">
          <div>{data.tablePhysicalNm}</div>
          <div>{data.tableLogicalNm}</div>
        </div>
        <div className="header">
          <div className="cell physical-name">KEY</div>
          <div className="cell physical-name">물리명</div>
          <div className="cell logical-name">논리명</div>
          <div className="cell logical-name">데이터 타입</div>
        </div>
        <div className="body">{renderField()}</div>
        <span className="handle-wrapper">
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Top}
            id="a"
          />
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Left}
            id="b"
          />
          <Handle
            type="source"
            className="targetHandle"
            position={Position.Right}
            id="c"
          />

          <Handle
            type="source"
            className="targetHandle"
            position={Position.Bottom}
            id="d"
          />
        </span>
      </div>
    </>
  );
});

/**
 * 전체 보기 영역 타입
 */
export const TrdAreaTemplateType = memo(({ data, id, selected, ...props }) => {
  const { areaNm, areaAffixType, areaCriteria } = data;

  const dispatch = useDispatch();

  let name = areaNm;
  if (areaAffixType && areaCriteria) {
    name += ` (${areaCriteria})`;
  }

  const onClickAreaDetail = () => {
    dispatch(selectTrd(data));
  };

  return (
    <>
      <NodeResizer color="dodgeblue" isVisible={selected} />
      <div
        style={{ width: "100%", height: "100%" }}
        onDoubleClick={onClickAreaDetail}
      >
        <div className="header">{`영역명 : ${name} `}</div>
      </div>
    </>
  );
});

/**
 * 메모 타입
 */
export const TrdMemoNodeType = memo(({ data, id, selected }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const descriptionRef = useRef();
  const titleRef = useRef();
  const dispatch = useDispatch();
  const trd = useSelector((state) => state.trd);
  const [titleEditMode, setTitleEditMode] = useState(false);

  /**
   * 내용 수정
   * @param {*} e
   */
  const onBlurDescription = (e) => {
    const description = descriptionRef.current.value;
    setIsEditMode(false);
    const newMemo = { ...data.memo, description };
    TrdReduxHelper.updateMemo(dispatch, newMemo, trd);
  };

  useEffect(() => {
    if (isEditMode && descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, [isEditMode]);

  /**
   * 메모 삭제
   */
  const onDeleteMemo = () => {
    TrdReduxHelper.deleteMemo(dispatch, id, trd);
  };

  /**
   * 메모 타이틀 수정
   * @param {*} e
   */
  const onBlurTitle = (e) => {
    const title = titleRef.current.value;
    setTitleEditMode(false);
    const newMemo = { ...data.memo, title };
    TrdReduxHelper.updateMemo(dispatch, newMemo, trd);
  };

  return (
    <>
      <NodeResizer
        color="dodgeblue"
        isVisible={selected}
        minWidth={200}
        minHeight={150}
        handleStyle={{
          width: "15px",
          height: "15px",
          borderRadius: "100%",
          zIndex: 1,
        }}
      />
      <div className="trd-memo nowheel ">
        <div className="header">
          {titleEditMode ? (
            <>
              <div className="title">
                <Form.Control
                  defaultValue={data.memo.title}
                  ref={titleRef}
                  onBlur={onBlurTitle}
                />
              </div>
              <div className="button-area">
                <button onClick={onBlurTitle}>
                  <MdCheck color="lime" />
                </button>
                <button onClick={(e) => setTitleEditMode(false)}>
                  <MdOutlineCancel color="tomato" />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="title">{data.memo.title}</div>
              <div className="button-area">
                <button onClick={(e) => setTitleEditMode(true)}>
                  <AiOutlineEdit color="lime" />
                </button>
                <button onClick={onDeleteMemo}>
                  <AiOutlineClose />
                </button>
              </div>
            </>
          )}
        </div>
        <div className="description-wrapper">
          {isEditMode ? (
            <>
              <textarea
                className="description nodrag"
                defaultValue={data.memo.description}
                ref={descriptionRef}
                onBlur={onBlurDescription}
                onClick={stopEvent}
                onScroll={stopEvent}
                onScrollCapture={stopEvent}
              />
              <div className="button-area">
                <Button
                  size="sm"
                  variant="outline-success"
                  onClick={onBlurDescription}
                >
                  확인
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={(e) => {
                    stopEvent(e);
                    setIsEditMode(false);
                  }}
                >
                  취소
                </Button>
              </div>
            </>
          ) : (
            <div
              className="description"
              onClick={stopEvent}
              onDoubleClick={(e) => setIsEditMode(true)}
            >
              {data.memo.description}
            </div>
          )}
        </div>
      </div>
    </>
  );
});
